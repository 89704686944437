.section {
	padding: 5rem 0;
}

.section-content {
	margin: 3rem auto 0;
	max-width: 930px;
	font-size: 1.25rem;

	@media all and (max-width: 767px) {
		font-size: 1.125rem;
	}

	@media all and (max-width: 680px) {
		font-size: 1rem;
	}
}

.section-heading {
	margin-bottom: 1.5em;
}
