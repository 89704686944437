.partners {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 2rem;
	margin-bottom: 2rem;

	@media (max-width: 767px) {
		justify-content: center;
	}
}

.partner {
	//display: flex;
	//justify-content: center;
	//align-items: center;
	//flex: 0 0 25%;
	//padding: 0 15px;
	//margin-bottom: 30px;
	//
	//@media (max-width: 991px) {
	//	flex: 0 0 33.3333%;
	//}
	//
	//@media (max-width: 767px) {
	//	flex: 0 0 50%;
	//}
	//
	//@media (max-width: 575px) {
	//	flex: 0 0 100%;
	//}
}
