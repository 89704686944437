.claim {

	h2 {
		margin-bottom: 3rem;
		text-shadow: 0 0 15px rgba(#000, .5);
	}

	.wrapper {
		background: linear-gradient(135deg, rgba(#004874, .4) 0%, rgba(#d8b97f, .4) 100%);
		padding: 14rem 0;
	}
}
