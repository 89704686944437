.logo-wrapper {
	//background-color: #fafaf8;
	position: absolute;
	z-index: 100;
	width: 220px;
	height: 120px;
	//border-radius: 50%;
	left: 50%;
	top: -50px;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateX(-50%);

	@media all and (max-width: 1160px) {
		left: 50px;
		transform: inherit;
	}

	@media all and (max-width: 767px) {
		top: inherit;
		left: inherit;
		height: 100%;
		width: auto;
		position: inherit;
		display: inline;

		img {
			width: 90px;
			height: auto;
		}
	}
}
