.contact-wrapper {
	background: linear-gradient(135deg, #004874 0%, #d8b97f 100%);

	a {
		color: white;

		&:hover {
			opacity: .8;
		}
	}
}
