$max-layout-width: 1290px;
$breakpoint-mobile: 680px;

html,
body {
	background: #fafaf8;
	color: #1b1b1b;
	position: relative;
	font-family: Montserrat, sans-serif;
}

.container {
	position: relative;
	width: 100%;
	max-width: $max-layout-width + 100px;
	margin: 0 auto;
	padding: 0 50px;

	@media all and (max-width: $breakpoint-mobile) {
		padding: 0 20px;
	}

	&__small {
		max-width: $breakpoint-mobile + 100px;
	}

	&__full {
		width: 100%;
	}
}

.triangle-top,
.triangle-bottom {
	position: relative;
}

.triangle-top {

	&:before {
		content: "";
		display: block;
		position: absolute;
		height: 13px;
		z-index: 2;
		left: 0;
		right: 0;
		top: 0;
	}
}

.triangle-bottom {

	&:after {
		content: "";
		display: block;
		position: absolute;
		height: 13px;
		z-index: 2;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.bg-white {
	background: #fafaf8;
}

#footer-site {
	padding: 1.5rem 0;

	.credits {
		text-align: right;
		font-size: .675rem;
		margin: 0;

		a {
			font-weight: 700;
			color: $primary;
			text-decoration: none;
		}
	}
}
