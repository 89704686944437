.button {
	display: inline-block;
	text-decoration: none;
	color: #363636;
	background: none;
	border: none;
	outline: 0;

	&:hover {
		cursor: pointer;
	}
}

.button-beach {
	padding: .75rem 3rem;
	background: #004874;
	color: white;
	font-weight: 800;
	transition: all .2s ease-in-out;
	transform: skew(-15deg);
	box-shadow: 6px 6px 0 black;
	text-transform: uppercase;
	letter-spacing: .3em;

	&:hover {
		color: #fbd33d;
		box-shadow: 10px 10px 0 #fbd33d;
		padding: .75rem 4rem;
		transform: skew(-15deg) scale(1.06) translateY(-4px);
	}
}
