.page-buttons {
	margin-top: 2rem;
}

.page-button {
	display: flex;
	flex-direction: column;
	min-width: 0;
	border-radius: 10px;
	background-color: #d8b97f;
	color: #004874;
	padding: 2rem;
	font-weight: 800;
	font-size: 1.5rem;
	text-decoration: none;
	transition: all .3s ease-in-out;
	transform: translateY(0);

	&:hover {
		background-color: #004874;
		color: #d8b97f;
		transform: translateY(-5px);
		box-shadow: 0 1rem 2rem rgba(0, 0, 0, 30%);
	}
}
