.intro {

	img {
		width: 100%;
		height: auto;
	}

	.container {
		min-height: 100%;
	}

	.intro-header {
		background: linear-gradient(135deg, rgba(#004874, .75) 0%, rgba(#d8b97f, .75) 100%);
		position: absolute;
		bottom: 3rem;
	}
}

.intro-header {
	display: inline-block;
	background: linear-gradient(135deg, #004874 0%, #d8b97f 100%);
	color: white;
	padding: .1rem 1rem;
	font-size: 3rem;
	border-radius: 8px;
}
