@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "~glightbox/dist/css/glightbox.min.css";
@import "layout/typography";
@import "layout/layout";
@import "layout/logo";
@import "layout/section";
@import "layout/menu";
@import "layout/intro";
@import "layout/gallery";
@import "layout/contact";
@import "layout/claim";
@import "layout/buttons";
@import "layout/partners";
@import "layout/page";
