.menu {
	display: flex;
	flex-wrap: nowrap;
	justify-content: end;
	list-style: none;
	margin: 0;
	padding: 0;

	@media all and (max-width: 767px) {
		padding-top: 1rem;
		flex-wrap: wrap;
		justify-content: center;
	}

	@media all and (max-width: 400px) {
		padding-top: 1rem;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

.menu-link {
	color: #004874;
	text-decoration: none;
	white-space: nowrap;

	&:hover {
		color: #d8b97f;
	}
}
